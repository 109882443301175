import { CustomLink as Link } from '@/components/custom-link';
import { VARIANT } from '@/components/inputs/button/button.types';
import Button from '@/components/inputs/button';
import { VARIANT as PartnerVariant } from '@/components/integrated/partner-tile/partner-tile.types';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import PartnerTile from '@/components/integrated/partner-tile/partner-tile';
import Image from '@/components/data-display/image';
import registerPartnerClick from '@/utils/register-partner-click/register-partner-click';
import { CLICK_LOCATION } from '@/utils/register-partner-click/register-partner-click.types';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME, NAV_ELEMENT } from '@/utils/track-event/track-event.types';
import type {
  HomeLastVisited as HomeLastVisitedType,
  HomeLastVisitedModuleProperties,
} from './home-last-visited.types';
import styles from './home-last-visited.module.scss';

const HomeLastVisited = ({
  homeLastVisited,
  noLastVisitedImage,
  visithistory_title,
  recentlyvisited_tabtitle,
}: HomeLastVisitedModuleProperties) => {
  return (
    <div className={styles['home-last-visited']}>
      <div className={styles['home-last-visited__content']}>
        <h2>{recentlyvisited_tabtitle}</h2>
        {homeLastVisited && homeLastVisited.length > 0 ? (
          homeLastVisited.map((lastVisited: HomeLastVisitedType) => (
            <Link
              href={`retailers/${lastVisited.partner.slug}`}
              key={lastVisited.partner.slug}
              onClick={() =>
                registerPartnerClick(
                  CLICK_LOCATION.HOME_LAST_VISITED,
                  lastVisited.partner.slug,
                )
              }
            >
              <PartnerTile
                variant={PartnerVariant.Inline}
                logo={{
                  src: lastVisited.partner.logo,
                  alt: lastVisited.partner.altLogo,
                }}
                name={lastVisited.partner.name}
                subField1={lastVisited.clickDate}
                subField2={lastVisited.clickId}
                showWasRate={false}
                className={styles['home-last-visited__partner-tile']}
              />
            </Link>
          ))
        ) : (
          <div className={styles['home-last-visited__nothing-to-display']}>
            {noLastVisitedImage?.url && (
              <Image
                src={noLastVisitedImage.url}
                alt={noLastVisitedImage.title}
                className={
                  styles['home-last-visited__nothing-to-display--image']
                }
                fill
              />
            )}
            <p
              className={
                styles['home-last-visited__nothing-to-display--heading']
              }
            >
              {noLastVisitedImage?.title}
            </p>
            <p
              className={
                styles['home-last-visited__nothing-to-display--paragraph']
              }
            >
              {noLastVisitedImage?.description}
            </p>
          </div>
        )}
      </div>

      <div className={styles['home-last-visited__ctas']}>
        <Button
          onClick={() =>
            trackEvent({
              event: EVENT_NAME.NAVIGATION,
              nav_element: NAV_ELEMENT.VISIT_HISTORY,
            })
          }
          as="link"
          variant={VARIANT.Text}
          trailingIcon={IconVariant.ChevronRight}
          href="/my-account/last-visited"
          title={visithistory_title}
        >
          {visithistory_title}
        </Button>
      </div>
    </div>
  );
};

export default HomeLastVisited;
