import { CustomLink as Link } from '@/components/custom-link';
import { VARIANT } from '@/components/inputs/button/button.types';
import Button from '@/components/inputs/button';
import { VARIANT as PartnerVariant } from '@/components/integrated/partner-tile/partner-tile.types';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import PartnerTile from '@/components/integrated/partner-tile/partner-tile';
import Image from '@/components/data-display/image';
import { DASHBOARD_MAX_ITEMS } from '@/modules/home/home-personalised-section/home-personalised-section.types';
import registerPartnerClick from '@/utils/register-partner-click/register-partner-click';
import { CLICK_LOCATION } from '@/utils/register-partner-click/register-partner-click.types';
import useHandleFavourites from '@/hooks/use-handle-favourites';
import type { favouriteTypeFormatted } from '@/hooks/use-handle-favourites/use-handle-favourites.types';
import useBasePath from '@/hooks/use-base-path';
import type { HomeFavouritesProperties } from './home-favourites.types';
import styles from './home-favourites.module.scss';

const HomeFavourites = ({
  favouritesLabel,
  noFavouritesImage,
  continueshopping_title,
}: HomeFavouritesProperties) => {
  const { favouritesList } = useHandleFavourites();
  const { basePath } = useBasePath();

  const splicedFavouriteList =
    favouritesList.length > 0
      ? favouritesList.splice(0, DASHBOARD_MAX_ITEMS)
      : [];

  return (
    <div className={styles['home-favourites']}>
      <div className={styles['home-favourites__content']}>
        <h2>{favouritesLabel}</h2>
        {splicedFavouriteList.length > 0 ? (
          splicedFavouriteList.map((favourite: favouriteTypeFormatted) => (
            <Link
              href={`${basePath}/retailers/${favourite.slug}`}
              key={favourite.slug}
              onClick={() =>
                registerPartnerClick(CLICK_LOCATION.HOME_FAVS, favourite.slug)
              }
            >
              <PartnerTile
                variant={PartnerVariant.Inline}
                logo={{
                  src: favourite.logo_url,
                  alt: favourite.name,
                }}
                name={favourite.name}
                rate={favourite.mechanic_collection_methods[0].rate}
                showWasRate={false}
                className={styles['home-favourites__partner-tile']}
              />
            </Link>
          ))
        ) : (
          <div className={styles['home-favourites__nothing-to-display']}>
            <Image
              src={noFavouritesImage?.url}
              alt="test"
              className={styles['home-favourites__nothing-to-display--image']}
              fill
            />
            <p
              className={styles['home-favourites__nothing-to-display--heading']}
            >
              {noFavouritesImage?.title}
            </p>
            <p
              className={
                styles['home-favourites__nothing-to-display--paragraph']
              }
            >
              {noFavouritesImage?.description}
            </p>
          </div>
        )}
      </div>

      <div className={styles['home-favourites__ctas']}>
        <Button
          as="link"
          href="/retailers"
          variant={VARIANT.Text}
          trailingIcon={IconVariant.ChevronRight}
          title={continueshopping_title}
        >
          {continueshopping_title}
        </Button>
      </div>
    </div>
  );
};

export default HomeFavourites;
