import Icon from '@/components/data-display/icon';
import { VARIANT } from '@/components/data-display/icon/icon.types';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import PartnerTile from '@/components/integrated/partner-tile';
import { VARIANT as PartnerVariant } from '@/components/integrated/partner-tile/partner-tile.types';
import Modal, { closeModal, toggleModal } from '@/components/utilities/modal';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import useSearch from '@/hooks/use-search';
import { DATA_IDS } from '@/types';
import { CustomLink as Link } from '@/components/custom-link';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import useBasePath from '@/hooks/use-base-path';
import styles from './search-modal.module.scss';

export type SearchModalProperties = {
  modalLabel: string;
  wasLabel?: string;
  title: string;
  closeButtonLabel: string;
};

const SearchModal = ({
  modalLabel,
  wasLabel,
  title,
  closeButtonLabel,
}: SearchModalProperties) => {
  const inputReference = useRef<HTMLInputElement>(null);
  const featureToggles = useFeatureTogglesContext();
  const disabledCLO = !featureToggles.includes(FEATURE_TOGGLE_LIST.CLO);

  const { push, query } = useRouter();
  const { basePath } = useBasePath();

  const { searchResults, searchValue, setSearchValue } = useSearch(
    null,
    query?.search as string,
    disabledCLO,
  );

  const goToSearch = () => {
    if (searchValue)
      push(`${basePath}/retailers?search=${encodeURIComponent(searchValue)}`);
    else push(`${basePath}/retailers`);

    toggleModal(modalLabel);
  };

  const handleOnKeyUp = ({ key }: React.KeyboardEvent<HTMLInputElement>) =>
    key === 'Enter' && goToSearch();

  const toggleSearchModal = () => {
    toggleModal(modalLabel);
    setSearchValue('');
  };

  return (
    <div className={styles[modalLabel]}>
      <Modal
        label={modalLabel}
        disableCloseButton
        closeOnOutsideClick
        className={styles['search-modal__modal']}
      >
        <div className={styles['search-modal__header']}>
          <header className={styles['search-modal__search-bar']}>
            <Icon
              variant={VARIANT.Search}
              className={styles['search-modal__search-bar__icon']}
              onClick={goToSearch}
            />

            <input
              ref={inputReference}
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
              className={styles['search-modal__search-bar__input']}
              data-id={DATA_IDS.SEARCH_MODAL_INPUT}
              placeholder={title}
              onKeyUp={handleOnKeyUp}
            />
            <Button
              variant={BUTTON_VARIANT.Unstyled}
              onClick={toggleSearchModal}
              aria-label={closeButtonLabel}
            >
              <Icon
                variant={VARIANT.X}
                className={styles['search-modal__search-bar__icon']}
              />
            </Button>
          </header>
        </div>
        <div className={styles['search-modal__content']}>
          <div className={styles['search-modal__results']}>
            {searchResults
              ?.filter(item => item.url)
              .map(item => {
                const previousRate =
                  item?.mechanic_collection_methods?.[0]?.offers[0]?.was_rate ??
                  undefined;
                return (
                  <Link
                    key={item.id}
                    href={item.url as string}
                    className={styles['search-modal__results__tile']}
                    onClick={() => closeModal(modalLabel)}
                  >
                    <PartnerTile
                      key={item.id}
                      variant={PartnerVariant.Inline}
                      logo={{
                        src: item.logoUrl,
                        alt: item.name,
                      }}
                      name={item.name}
                      rate={
                        item.mechanic_collection_methods &&
                        item.mechanic_collection_methods.length > 0
                          ? item.mechanic_collection_methods?.[0]?.rate
                          : null
                      }
                      wasRate={
                        previousRate ? `${wasLabel} ${previousRate}` : ''
                      }
                      showWasRate={Boolean(previousRate)}
                    />
                  </Link>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchModal;
