import { GetServerSidePropsContext } from 'next';
import { getSession } from '../../../providers/auth0';
import CollinsonProvider from '../../../providers/collinson/collinson.provider';
import Partner from '../../../models/partner/partner';
import { HomeLastVisitedProperties } from './home-last-visited.types';
import { toShortDateFormat } from '../../../utils/date-formats';
import { DASHBOARD_MAX_ITEMS } from '../home-personalised-section/home-personalised-section.types';
import { getCollinsonUser } from '../../../providers/collinson/collinson.utils';

export const homeLastVisitedHandler = async ({
  req,
  res,
}: GetServerSidePropsContext): Promise<HomeLastVisitedProperties> => {
  const { market, b2bToken } = req;

  const session = await getSession({ market, requestResponse: [req, res] });
  if (!session?.user) return { homeLastVisited: [] };

  const collinson = new CollinsonProvider(market);
  const collinsonUser = await getCollinsonUser(session, market);
  if (!collinsonUser) return { homeLastVisited: [] };

  const clicks = await collinson.getClicks(collinsonUser.id, {
    limit: '15',
  });

  const externalReferences = clicks?.hasError
    ? null
    : clicks.data?.map(x => x.merchant_id);

  if (!externalReferences || externalReferences.length === 0)
    return { homeLastVisited: [] };

  const partners = externalReferences
    ? await Partner.findByConditions({
        market,
        b2bToken,
        externalReferences,
      })
    : [];

  await Promise.all(partners.map(partner => partner.isReady()));

  return {
    homeLastVisited: partners
      .filter(
        (value, index, array) =>
          value.isValid &&
          value.id !== null &&
          index === array.findIndex(partner => partner.id === value.id),
      )
      .sort((a, b) => {
        return (
          externalReferences.indexOf(a.externalReference) -
          externalReferences.indexOf(b.externalReference)
        );
      })
      .slice(0, DASHBOARD_MAX_ITEMS)
      .map(partner => {
        const {
          id,
          name,
          slug,
          logoSrc,
          externalReference,
          destinationUrl = '',
        } = partner;

        const merchant = clicks.data.find(
          merchantElement => merchantElement.merchant_id === externalReference,
        );

        return {
          clickId: merchant?.id as string,
          clickDate: toShortDateFormat(merchant?.date as string, market.locale),
          partner: {
            id,
            name,
            slug,
            altLogo: slug,
            logo: logoSrc,
            ctaUrl: destinationUrl,
          },
        };
      }),
  };
};
