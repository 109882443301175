import { GetServerSidePropsContext } from 'next';
import { VARIANT } from '@/components/integrated/purchase-status/purchase-status.types';
import { getSession } from '../../../providers/auth0';
import CollinsonProvider from '../../../providers/collinson/collinson.provider';
import { HomeLastPurchasesProperties } from './home-last-purchases.types';
import { DASHBOARD_MAX_PURCHASES } from '../home-personalised-section/home-personalised-section.types';
import { getCollinsonUser } from '../../../providers/collinson/collinson.utils';

export const homeLastPurchasesHandler = async ({
  req,
  res,
}: GetServerSidePropsContext): Promise<HomeLastPurchasesProperties> => {
  const { market } = req;

  const session = await getSession({ market, requestResponse: [req, res] });
  if (!session?.user)
    return { homePendingPurchases: [], homeAwardedPurchases: [] };

  const collinson = new CollinsonProvider(market);
  const collinsonUser = await getCollinsonUser(session, market);
  if (!collinsonUser)
    return { homePendingPurchases: [], homeAwardedPurchases: [] };

  const awardedPurchases = await collinson.getPurchaseHistory(
    collinsonUser.id,
    {
      limit: DASHBOARD_MAX_PURCHASES.toString(),
      status: VARIANT.Awarded,
    },
  );

  const pendingPurchases = await collinson.getPurchaseHistory(
    collinsonUser.id,
    {
      limit: DASHBOARD_MAX_PURCHASES.toString(),
      status: VARIANT.Pending,
    },
  );

  return {
    homePendingPurchases: pendingPurchases.hasError
      ? []
      : pendingPurchases.data,
    homeAwardedPurchases: awardedPurchases.hasError
      ? []
      : awardedPurchases.data,
  };
};
