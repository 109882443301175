import { useRef, useLayoutEffect, useState } from 'react';
import { useUser } from '@/providers/auth0';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import HeroCard from '@/components/integrated/hero-card';
import {
  LOGO_SIZE,
  TEXT_SIZE,
} from '@/components/integrated/hero-card/hero-card.types';
import Tabs from '@/components/navigation/tabs';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { VARIANT as PurchaseStatusVariant } from '@/components/integrated/purchase-status/purchase-status.types';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import HomeLastPurchases from '@/modules/home/home-last-purchases';
import HomeLastVisited from '@/modules/home//home-last-visited';
import HomeFavourites from '@/modules/home/home-favourites';
import { DATA_IDS } from '@/types';
import useHandleFavourites from '@/hooks/use-handle-favourites';
import type { HomePersonalisedSectionProperties } from './home-personalised-section.types';
import styles from './home-personalised-section.module.scss';

const HomePersonalisedSection = ({
  titleLabel,
  paidForMerchant,
  favouritesLabels,
  paidForMerchantHeroImage,
  noFavouritesImage,
  noLastVisitedImage,
  noAwardedPurchases,
  noPendingPurchases,
  homeLastVisited,
  homeAwardedPurchases,
  homePendingPurchases,
  microcopy,
}: HomePersonalisedSectionProperties) => {
  const { user } = useUser();
  const [tabsHeight, setTabsHeight] = useState(0);
  const tabsReference = useRef(null);

  const { isFavourite, toggleFavourite } = useHandleFavourites();

  const featuresToggles = useFeatureTogglesContext();
  const showFavourites =
    user && !featuresToggles.includes(FEATURE_TOGGLE_LIST.NO_FAVS);

  useLayoutEffect(() => {
    const tabsReferenceConst: any = tabsReference.current;
    if (tabsReferenceConst) {
      setTabsHeight(tabsReferenceConst.offsetHeight);
    }
  }, []);

  return (
    <>
      {user && titleLabel && (
        <h1 className={styles['home-personalised-section--heading']}>
          {`${titleLabel} ${user.name}`}
        </h1>
      )}
      <div className={styles['home-personalised-section']}>
        {paidForMerchant && paidForMerchantHeroImage && (
          <div
            className={styles['home-personalised-section__partner-hero-card']}
          >
            <HeroCard
              image={{
                smallImage: {
                  title: paidForMerchant?.name,
                  url: paidForMerchantHeroImage?.url,
                  description: paidForMerchant?.name,
                  height: tabsHeight,
                  width: paidForMerchantHeroImage?.width,
                },
              }}
              favourite={{
                favouritesLabels,
                showFavourite: showFavourites,
                favouriteId:
                  isFavourite(paidForMerchant?.mechanicId) ?? undefined,
                toggleFavourite,
              }}
              partner={{
                id: paidForMerchant?.id,
                mechanicId: paidForMerchant?.mechanicId,
                name: paidForMerchant?.name,
                imageSrc: paidForMerchant?.logoSrc,
                imageAlt: paidForMerchant?.name,
                rate: paidForMerchant?.rate,
                wasRate: paidForMerchant?.wasRate,
                isSpeedyAwarding: paidForMerchant?.isSpeedyAwarding,
                logoSize: LOGO_SIZE.medium,
                textSize: TEXT_SIZE.medium,
                speedyAwardText: microcopy?.speedyaward_title,
              }}
              ctaLabel={microcopy?.home_personalisedsection_ctalabel}
              ctaUrl={paidForMerchant?.categoryUrl}
              ctaTrailingIcon={ICON_VARIANT.ChevronRight}
              ctaVariant={BUTTON_VARIANT.Text}
              partnerLink={paidForMerchant.destinationUrl}
            />
          </div>
        )}
        <div
          ref={tabsReference}
          className={styles['home-personalised-section__tabs']}
        >
          <Tabs>
            <Tabs.List>
              {showFavourites ? (
                <Tabs.Tab
                  panelId="1"
                  activeOnLoad={showFavourites}
                  title={microcopy?.favourites_tabtitle}
                  aria-label={microcopy?.favourites_tabtitle}
                  data-id={DATA_IDS.PERSONALISED_SECTION_FAVOURITES_TAB}
                >
                  <Icon
                    variant={ICON_VARIANT.Heart}
                    data-id={ICON_VARIANT.Heart}
                  />
                </Tabs.Tab>
              ) : null}
              <Tabs.Tab
                panelId="2"
                activeOnLoad={!showFavourites}
                title={microcopy?.recentlyvisited_tabtitle}
                aria-label={microcopy?.recentlyvisited_tabtitle}
                data-id={DATA_IDS.PERSONALISED_SECTION_RECENTLY_VISITED_TAB}
              >
                <Icon
                  variant={ICON_VARIANT.History}
                  data-id={ICON_VARIANT.History}
                />
              </Tabs.Tab>
              <Tabs.Tab
                panelId="3"
                title={microcopy?.recent_tabtitle}
                aria-label={microcopy?.recent_tabtitle}
                data-id={DATA_IDS.PERSONALISED_SECTION_RECENT_TRANSACTIONS_TAB}
              >
                <Icon
                  variant={ICON_VARIANT.AviosPlectrum}
                  data-id={ICON_VARIANT.AviosPlectrum}
                />
              </Tabs.Tab>
              <Tabs.Tab
                panelId="4"
                title={microcopy?.pending_tabtitle}
                aria-label={microcopy?.pending_tabtitle}
                data-id={DATA_IDS.PERSONALISED_SECTION_PENDING_TRANSACTIONS_TAB}
              >
                <Icon
                  variant={ICON_VARIANT.Clock}
                  data-id={ICON_VARIANT.Clock}
                />
              </Tabs.Tab>
            </Tabs.List>

            {showFavourites ? (
              <Tabs.Panel id="1">
                <HomeFavourites
                  favouritesLabel={microcopy?.favourites_tabtitle}
                  noFavouritesImage={noFavouritesImage}
                  continueshopping_title={microcopy?.continueshopping_title}
                />
              </Tabs.Panel>
            ) : null}
            <Tabs.Panel id="2">
              <HomeLastVisited
                homeLastVisited={homeLastVisited}
                noLastVisitedImage={noLastVisitedImage}
                {...microcopy}
              />
            </Tabs.Panel>
            <Tabs.Panel id="3">
              <HomeLastPurchases
                lastPurchasesType={PurchaseStatusVariant.Awarded}
                lastPurchasesData={homeAwardedPurchases}
                lastPurchasesLabels={{
                  labelAmount: microcopy?.purchasehistory_amount,
                  labelAvios: microcopy?.purchasehistory_avios,
                  labelAwarded: microcopy?.purchasehistory_status_awarded,
                  labelPending: microcopy?.purchasehistory_status_pending,
                  labelPurchaseHistory: microcopy?.purchasehistory_title,
                  labelTabTitle: microcopy?.recent_tabtitle,
                }}
                noPurchases={noAwardedPurchases}
              />
            </Tabs.Panel>
            <Tabs.Panel id="4">
              <HomeLastPurchases
                lastPurchasesType={PurchaseStatusVariant.Pending}
                lastPurchasesData={homePendingPurchases}
                lastPurchasesLabels={{
                  labelAmount: microcopy?.purchasehistory_amount,
                  labelAvios: microcopy?.purchasehistory_avios,
                  labelAwarded: microcopy?.purchasehistory_status_awarded,
                  labelPending: microcopy?.purchasehistory_status_pending,
                  labelPurchaseHistory: microcopy?.purchasehistory_title,
                  labelTabTitle: microcopy?.pending_tabtitle,
                }}
                noPurchases={noPendingPurchases}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default HomePersonalisedSection;
