import { CustomLink as Link } from '@/components/custom-link';
import type {
  TableHeadProperties,
  TableRowProperties,
} from '@/components/data-display/table/table.types';
import type { PurchaseHistoryData } from '@/providers/collinson/collinson.types';
import { formatCurrency } from '@/utils/currency-helper';
import { toShortDateFormat } from '@/utils/date-formats';
import type { LastPurchaseLabels } from './home-last-purchases.types';
import styles from './home-last-purchases.module.scss';

export function toLastPurchaseTableRows(
  data?: PurchaseHistoryData[],
): TableRowProperties[] {
  if (!data?.length) return [];

  return data.map(purchase => {
    if (!purchase) return { row: [] };
    return {
      row: [
        {
          data: (
            <Link
              href={`/retailers/${getPartnerNameToSlug(purchase.name)}`}
              className={styles['home-last-purchases__table-content']}
            >
              <div
                className={
                  styles['home-last-purchases__table-description-container']
                }
              >
                {purchase.name}
                <div
                  className={
                    styles[
                      'home-last-purchases__table-description-sub-container'
                    ]
                  }
                >
                  <span>{toShortDateFormat(purchase.date)}</span>
                  <span
                    className={
                      styles[
                        'home-last-purchases__table-description-sub-container__separator'
                      ]
                    }
                  >
                    •
                  </span>
                  <span>{purchase.id}</span>
                </div>
              </div>
            </Link>
          ),
        },
        { data: `${purchase.commission}` },
        { data: `${formatCurrency(purchase.amount, purchase.currency)}` },
      ],
    };
  });
}

export function toLastPurchaseTableHeads(
  lastPurchaseLabels?: LastPurchaseLabels,
  isAwarded?: boolean,
): TableHeadProperties[] {
  return [
    {
      data: isAwarded
        ? lastPurchaseLabels?.labelAwarded
        : lastPurchaseLabels?.labelPending,
    },
    { data: lastPurchaseLabels?.labelAvios },
    { data: lastPurchaseLabels?.labelAmount },
  ];
}

export function getPartnerNameToSlug(partnerName: string): string {
  return partnerName
    .replaceAll('&', 'and')
    .replace(/[^\d A-Za-z-]/g, '')
    .replaceAll(' ', '-')
    .toLocaleLowerCase();
}
