import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React from 'react';
import { useUser } from '@/providers/auth0';
import HomePageModule from '@/modules/home/home-page-module/home-page-module';
import { homePageModuleHandler } from '@/modules/home/home-page-module/home-page-module.handler';
import { HomePageModuleProperties } from '@/modules/home/home-page-module/home-page-module.types';
import { HomeLastVisitedProperties } from '@/modules/home/home-last-visited/home-last-visited.types';
import { homeLastVisitedHandler } from '@/modules/home/home-last-visited';
import { HomeSectionProperties } from '@/modules/home/home-section/home-section.types';
import HomeSection, { homeSectionHandler } from '@/modules/home/home-section';
import { homeTopPicksCarouselHandler } from '@/modules/home/home-top-picks-carousel';
import { TopPicksCarouselProperties } from '@/modules/home/home-top-picks-carousel/home-top-picks-carousel.types';
import Loader from '@/components/feedback/loader';
import { HomeLastPurchasesProperties } from '@/modules/home/home-last-purchases/home-last-purchases.types';
import { homeLastPurchasesHandler } from '@/modules/home/home-last-purchases';
import { globalServerSideContext } from '@/utils/server-side-context';
import styles from './home.module.scss';

export type HomePageProperties = {
  homePageModuleProperties: HomePageModuleProperties;
  homeLastVisitedProperties: HomeLastVisitedProperties;
  homeLastPurchasesProperties: HomeLastPurchasesProperties;
  homeSectionProperties: HomeSectionProperties;
  homeTopPicksCarouselProperties: TopPicksCarouselProperties | null;
};

const HomePage = ({
  homeLastVisitedProperties,
  homeLastPurchasesProperties,
  homePageModuleProperties,
  homeSectionProperties,
  homeTopPicksCarouselProperties,
}: HomePageProperties) => {
  const { isLoading } = useUser();
  const { pageTitle, pageDescription, pageKeywords } = homePageModuleProperties;
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />

        {pageDescription && (
          <>
            <meta name="description" content={pageDescription} />
            <meta name="og:description" content={pageDescription} />
          </>
        )}

        {pageKeywords && <meta name="keywords" content={pageKeywords} />}
      </Head>

      {isLoading ? (
        <div className={styles.home}>
          <Loader className={styles.home__loader} />
        </div>
      ) : (
        <>
          <HomePageModule
            {...homePageModuleProperties}
            homeLastVisitedProperties={homeLastVisitedProperties}
            homeLastPurchasesProperties={homeLastPurchasesProperties}
            homeTopPicksCarouselProperties={
              homeSectionProperties.hasTopPicksInGpt
                ? null
                : homeTopPicksCarouselProperties
            }
          />
          {homeSectionProperties && <HomeSection {...homeSectionProperties} />}
        </>
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  HomePageProperties
> = async context =>
  globalServerSideContext().useContext(async () => {
    const [
      homePageModuleProperties,
      homeLastVisitedProperties,
      homeLastPurchasesProperties,
      homeSectionProperties,
      homeTopPicksCarouselProperties,
    ] = await Promise.all([
      homePageModuleHandler(context),
      homeLastVisitedHandler(context),
      homeLastPurchasesHandler(context),
      homeSectionHandler(context),
      homeTopPicksCarouselHandler(context),
    ]);

    return {
      props: {
        homePageModuleProperties,
        homeLastVisitedProperties,
        homeLastPurchasesProperties,
        homeSectionProperties,
        homeTopPicksCarouselProperties,
      },
    };
  });

export default HomePage;
