import { ReactNode } from 'react';

export enum VARIANT {
  Default = 'table--default',
  Secondary = 'table--secondary',
}

export type TableProperties = {
  rows: TableRowProperties[];
  heads: TableHeadProperties[];
  noDataPlaceholder?: string | ReactNode;
  className?: string;
  noDataClassName?: string;
  hasEmptyTableRow?: boolean;
  variant: VARIANT;
};

export type TableHeadProperties = {
  id?: string;
  data: string | ReactNode;
};

export type TableRowProperties = {
  row: TableCell[];
};

export type TableCell = {
  id?: string;
  data: string | ReactNode;
};
