export enum VARIANT {
  Unknown = '0',
  Pending = '1',
  Awarded = '2',
  Cancelled_merchant = '3', // Cancelled by merchant
  Cancelled = '4',
}

export type PurchaseStatusProperties = {
  labelAwarded: string;
  labelPending: string;
  labelCancelled: string;
  labelUnknown: string;
  statusCode: string;
  className?: string;
};
