import { VARIANT } from '@/components/inputs/button/button.types';
import Button from '@/components/inputs/button';
import { VARIANT as PurchaseStatusVariant } from '@/components/integrated/purchase-status/purchase-status.types';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import Table from '@/components/data-display/table';
import { VARIANT as TableVariant } from '@/components/data-display/table/table.types';
import { EVENT_NAME, NAV_ELEMENT } from '@/utils/track-event/track-event.types';
import trackEvent from '@/utils/track-event/track-event';
import {
  toLastPurchaseTableHeads,
  toLastPurchaseTableRows,
} from './home-last-purchases.utils';
import type { HomeLastPurchasesModuleProperties } from './home-last-purchases.types';
import styles from './home-last-purchases.module.scss';

const HomeLastPurchasesPurchases = ({
  lastPurchasesType,
  lastPurchasesData,
  noPurchases,
  lastPurchasesLabels,
}: HomeLastPurchasesModuleProperties) => {
  const tableHeads = toLastPurchaseTableHeads(
    lastPurchasesLabels,
    lastPurchasesType === PurchaseStatusVariant.Awarded,
  );
  const tableRows = toLastPurchaseTableRows(lastPurchasesData);

  return (
    <div className={styles['home-last-purchases']}>
      <div className={styles['home-last-purchases__content']}>
        <h2>{lastPurchasesLabels?.labelTabTitle}</h2>
        {lastPurchasesData && lastPurchasesData.length > 0 ? (
          <Table
            variant={TableVariant.Secondary}
            heads={tableHeads}
            rows={tableRows}
            className={styles['home-last-purchases-table']}
          />
        ) : (
          <div className={styles['home-last-purchases__nothing-to-display']}>
            <p
              className={
                styles['home-last-purchases__nothing-to-display--heading']
              }
            >
              {noPurchases?.title}
            </p>
            <p
              className={
                styles['home-last-purchases__nothing-to-display--paragraph']
              }
            >
              {noPurchases?.description}
            </p>
          </div>
        )}
      </div>

      <div className={styles['home-last-purchases__ctas']}>
        <Button
          onClick={() =>
            trackEvent({
              event: EVENT_NAME.NAVIGATION,
              nav_element: NAV_ELEMENT.PURCHASE_HISTORY,
            })
          }
          as="link"
          variant={VARIANT.Text}
          trailingIcon={IconVariant.ChevronRight}
          href="/my-account/purchase-history"
          title={lastPurchasesLabels?.labelPurchaseHistory}
        >
          {lastPurchasesLabels?.labelPurchaseHistory}
        </Button>
      </div>
    </div>
  );
};

export default HomeLastPurchasesPurchases;
