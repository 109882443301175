import { DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE } from '@/utils/contentful-data-formatters/contentful-data-formatters.types';
import { GetServerSidePropsContext } from 'next';
import { getSession } from '@/providers/auth0';
import ContentfulProvider from '@/providers/contentful';
import {
  formatSections,
  hydrateSections,
} from '@/utils/contentful-data-formatters';
import { formatMicrocopy } from '@/utils/format-microcopy';
import { homeSectionQuery } from './home-section.query';
import {
  ContentGrid,
  HomeSectionProperties,
  Section,
  Sections,
} from './home-section.types';

const hasTopPicksInContentGrid = (contentGrid: ContentGrid) => {
  return contentGrid?.itemsCollection?.items.some(
    content =>
      content?.externalSource ===
      DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE.TopPicks,
  );
};

const hasTopPicksInSection = (section: Section) => {
  return section?.contentCollection.items.some(contentGrid =>
    hasTopPicksInContentGrid(contentGrid),
  );
};

const hasTopPicksInSections = (sections: Sections) => {
  return sections?.items.some(section => hasTopPicksInSection(section));
};

export const homeSectionHandler = async ({
  req,
  res,
}: GetServerSidePropsContext): Promise<HomeSectionProperties> => {
  const { market, b2bToken } = req;

  const session = await getSession({ market, requestResponse: [req, res] });

  const contentfulRequest = new ContentfulProvider(
    market,
  ).makeContentfulRequest<any>({
    query: homeSectionQuery,
  });

  const contentfulSectionResponse = await contentfulRequest;

  const microcopy = formatMicrocopy(
    contentfulSectionResponse.data.microcopyCollection.items,
  );

  const { topSectionCollection, bottomSectionCollection } =
    contentfulSectionResponse.data.pageHomeLinkedMediaContentCollection
      .items[0];

  const updatedTopSection = await hydrateSections(
    topSectionCollection,
    market,
    b2bToken,
    session,
  );

  const updatedBottomSection = await hydrateSections(
    bottomSectionCollection,
    market,
    b2bToken,
    session,
  );

  return {
    topSection: formatSections(updatedTopSection, microcopy) ?? null,
    bottomSection: formatSections(updatedBottomSection, microcopy) ?? null,
    hasTopPicksInGpt: [topSectionCollection, bottomSectionCollection].some(
      sections => hasTopPicksInSections(sections),
    ),
  };
};
