import classNames from 'classnames';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import type { SectionHeadingProperties } from './section-heading.types';
import styles from './section-heading.module.scss';

const SectionHeading = ({
  title,
  titleColor,
  viewAllPath,
  viewAllLabel = '',
  className,
  viewAllClassName,
  GAEventReference = null,
  viewAllDataId,
}: SectionHeadingProperties) => {
  const titleStyles = {
    color: titleColor ?? undefined,
  };

  if (!title && !(viewAllLabel && viewAllPath)) {
    return null;
  }

  const handleGAEvents = () => {
    if (GAEventReference) {
      trackEvent({
        event: EVENT_NAME.NAVIGATION,
        nav_element: GAEventReference,
      });
    }
  };

  return (
    <div className={classNames(styles['section-heading'], className)}>
      {title && (
        <h2 style={titleStyles} className={styles['section-heading__title']}>
          {title}
        </h2>
      )}
      {viewAllLabel && (
        <Button
          onClick={handleGAEvents}
          variant={BUTTON_VARIANT.TextBlock}
          as="link"
          href={viewAllPath}
          trailingIcon={ICON_VARIANT.ChevronRight}
          className={viewAllClassName}
          title={viewAllLabel}
          dataId={viewAllDataId}
        >
          {viewAllLabel}
        </Button>
      )}
    </div>
  );
};

export default SectionHeading;
