import { PurchaseHistoryData } from '../../../providers/collinson/collinson.types';
import { HomeLastVisited } from '../home-last-visited/home-last-visited.types';

export type HomePersonalisedSectionProperties = {
  titleLabel: string;
  paidForMerchant: {
    name: string;
    id?: number;
    description?: string;
    categoryUrl?: string;
    isSpeedyAwarding?: boolean;
    destinationUrl: string;
    slug: string;
    logoSrc: string;
    overide: {
      heroSrc: string;
    };
    mechanicId: number;
    rate: string;
    wasRate: string;
  };
  paidForMerchantHeroImage: {
    url: string;
    height: number;
    width: number;
    description: string;
  };
  noFavouritesImage: {
    url: string;
    title: string;
    description: string;
  };
  favouritesLabels: {
    addToFavourites: string;
    removeFromFavourites: string;
  };
  noLastVisitedImage: {
    url: string;
    title: string;
    description: string;
  };
  noPendingPurchases: {
    title: string;
    description: string;
  };
  noAwardedPurchases: {
    title: string;
    description: string;
  };
  homeLastVisited?: HomeLastVisited[];
  homeAwardedPurchases?: PurchaseHistoryData[];
  homePendingPurchases?: PurchaseHistoryData[];
  microcopy?: {
    continueshopping_title?: string;
    visithistory_title?: string;
    recentlyvisited_tabtitle?: string;
    merchant_rateupto?: string;
    merchant_ratewas?: string;
    favourites_tabtitle?: string;
    recent_tabtitle?: string;
    pending_tabtitle?: string;
    purchasehistory_status_awarded?: string;
    purchasehistory_status_pending?: string;
    purchasehistory_amount?: string;
    purchasehistory_avios?: string;
    purchasehistory_title?: string;
    home_personalisedsection_ctalabel?: string;
    speedyaward_title?: string;
  };
};

export const DASHBOARD_MAX_ITEMS = 3;
export const DASHBOARD_MAX_PURCHASES = 3;
