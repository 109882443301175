import { HomeSectionProperties } from './home-section.types';
import ContentfulRenderSection from '../../contentful-section/contentful-section';

const HomeSection = ({ topSection, bottomSection }: HomeSectionProperties) => {
  return (
    <>
      {topSection?.length ? (
        <ContentfulRenderSection sections={topSection} />
      ) : null}
      {bottomSection?.length ? (
        <ContentfulRenderSection sections={bottomSection} />
      ) : null}
    </>
  );
};

export default HomeSection;
