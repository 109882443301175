import classNames from 'classnames';
import styles from './table.module.scss';
import {
  TableHeadProperties,
  TableProperties,
  TableRowProperties,
} from './table.types';

const TableHead = ({ id, data }: TableHeadProperties) => {
  return (
    <td className={styles.table__td} title={id}>
      {data}
    </td>
  );
};

const TableRow = ({ row }: TableRowProperties) => {
  return (
    <tr className={styles.table__tr}>
      {row.map((item, index) => {
        return (
          <td className={styles.table__td} key={item.id ?? index}>
            {item.data}
          </td>
        );
      })}
    </tr>
  );
};

const Table = ({
  heads,
  rows,
  noDataPlaceholder,
  className = '',
  noDataClassName = '',
  hasEmptyTableRow,
  variant,
}: TableProperties) => {
  const variantClass = styles[variant];

  return (
    <div className={classNames(styles.table, variantClass, className)}>
      {rows?.length > 0 ? (
        <table className={styles.table__table}>
          <thead className={styles.table__head}>
            <tr className={styles.table__tr}>
              {heads?.map((item, index) => {
                return (
                  <TableHead
                    key={item.id ?? index}
                    id={item.id}
                    data={item.data}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.table__body}>
            {rows.map((item, index) => {
              return (
                <TableRow key={item.row?.[0]?.id ?? index} row={item.row} />
              );
            })}
            {hasEmptyTableRow && <tr />}
          </tbody>
        </table>
      ) : (
        <div className={classNames(styles['table__no-data'], noDataClassName)}>
          {noDataPlaceholder}
        </div>
      )}
    </div>
  );
};

export default Table;
