import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import styles from './tabs.module.scss';
import {
  TabsProperties,
  TabsTabProperties,
  TabsListProperties,
  TabsPanelProperties,
} from './tabs.types';

const TabsContext = createContext({
  activeTabId: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTabChange: (tabId: string) => {},
});

const TabsTab = ({
  panelId,
  activeOnLoad = false,
  children,
  onTabClick,
  className = '',
  ...restProperties
}: TabsTabProperties) => {
  const { activeTabId, onTabChange } = useContext(TabsContext);

  useEffect(() => {
    if (activeTabId === '' && activeOnLoad) {
      onTabChange(panelId);
    }
  });

  const isActive = activeTabId === panelId;

  return (
    <li>
      <button
        role="tab"
        type="button"
        aria-selected={isActive}
        aria-controls={panelId}
        onClick={() => {
          onTabChange(panelId);
          if (onTabClick) onTabClick();
        }}
        className={classNames(
          styles.tabs__tab,
          { [styles['tabs__tab--active']]: isActive },
          className,
        )}
        {...restProperties}
      >
        {children}
      </button>
    </li>
  );
};

const TabsList = ({ children, ...restProperties }: TabsListProperties) => (
  <ul className={styles.tabs__list} {...restProperties}>
    {children}
  </ul>
);

const TabsPanel = ({
  id,
  children,
  ...restProperties
}: TabsPanelProperties) => {
  const { activeTabId } = useContext(TabsContext);
  const isActive = activeTabId === id;

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <div
      className={styles.tabs__panel}
      role="tabpanel"
      id={id}
      hidden={!isActive}
      aria-expanded={isActive}
      {...restProperties}
    >
      {children}
    </div>
  );
};

const Tabs = ({
  children,
  className = '',
  ...restProperties
}: TabsProperties) => {
  const [activeTabId, setActiveTabId] = useState('');

  const initialContextValue = useMemo(
    () => ({
      activeTabId,
      onTabChange: (tabId: string) => setActiveTabId(tabId),
    }),
    [activeTabId, setActiveTabId],
  );

  return (
    <TabsContext.Provider value={initialContextValue}>
      <div
        className={`${styles.tabs} ${className}`}
        role="tablist"
        {...restProperties}
      >
        {children}
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Tab = TabsTab;
Tabs.List = TabsList;
Tabs.Panel = TabsPanel;
export default Tabs;
