import { heroFields } from '@/providers/contentful/contentful-graphql-fragments';
import { versatileCardFields } from '../../../providers/contentful';

export const homePageModuleQuery = `query HomePageModuleQuery($contentfulOpCoId: String!, $countryId: String, $locale: String!) {
	pageHomeLinkedMediaContentCollection(
	  locale: $locale
	  where: {programId: $contentfulOpCoId, countryId: $countryId}
	  limit: 1
	) {
		items {
			name
			noFavouritesImage {
				description
				title
				url
			  }
			personalisedSection {
			  titleLabel,
			  paidForMerchant,
			  paidForMerchantHeroImage {
				url,
				height,
				width,
				description,
			  }
			}
			noLastVisitedImage {
				description
				title
				url
			  }
			headingPopularBrands,
			viewAllPopularBrands,
			showPromotion,
            promotionText,
            promotionTitle,
            promotionPath,
            promotionTerms,
            promotionBackgroundColor,

			amexBannerV2 {
				...versatileCardFields
			}
			amexClassicBannerV2 {
				...versatileCardFields
			}
			amexPremiumBannerV2 {
				...versatileCardFields
			}
			amexBusinessBannerV2 {
				...versatileCardFields
			}
			carouselBannersCollection(limit: 3){
				items{
					content {
						...versatileCardFields
					}
				}
			}
			heroSection {
				...heroSectionFields
			}
			pageTitle
			pageDescription
			pageKeywords
			pageHeading
			pageHeadingTypewritter
		}
	  }
	  microcopyCollection(locale: $locale, where: {
		key_in: [
		   "visithistory.title",
		   "continueShopping.title",
		   "favourites.tabTitle",
		   "recentlyVisited.tabTitle",
		   "pending.tabTitle",
		   "recent.tabTitle",
		   "merchant.rateupto",
		   "merchant.ratewas",
		   "merchant.ratefrom",
		   "home.personalisedSection.ctaLabel",
		   "home.noPendingPurchases.title",
		   "home.noPendingPurchases.description",
		   "home.noAwardedPurchases.title",
		   "home.noAwardedPurchases.description",
		   "purchasehistory.status.awarded",
		   "purchasehistory.status.pending",
		   "purchasehistory.amount",
		   "purchasehistory.avios",
		   "purchasehistory.title",
		   "speedyAward.title"
		   "favourites.button.addToFavourites"
		   "favourites.button.removeFromFavourites"
		   "home.section.ariaLabel.infoBanner"
		   "home.section.ariaLabel.heading"
		   "home.section.ariaLabel.carousel"
		   "home.section.ariaLabel.creditCardOffer"
		   "home.section.ariaLabel.topPicks"
		   "home.section.ariaLabel.tailoredForYou"
		   "shopNow.loggedInCtaLabel"
	   	]
	}) {
		items {
			key
		  value
		}
	  },
  }
  ${versatileCardFields}
  ${heroFields}`;
