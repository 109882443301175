import Image from '@/components/data-display/image';
import Button from '@/components/inputs/button';
import { VARIANT } from '@/components/inputs/button/button.types';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import SearchModal from '@/modules/search-modal';
import { toggleModal } from '@/components/utilities/modal';
import { Eyebrow, Heading } from '@alto-avios/alto-ui';
import { SearchSectionProperties } from './search-section.types';
import styles from './search-section.module.scss';

const SearchSection = ({
  searchButtonLabel,
  image,
  pillLabel,
  heading,
  body,
  button,
  wasRateLabel,
  closeButtonLabel,
  backgroundColour,
  enableSearch,
}: SearchSectionProperties) => {
  const modalLabel = 'search-modal';

  return (
    <section
      className={styles[`search-section`]}
      style={{
        background: backgroundColour ?? 'initial',
      }}
    >
      {image && (
        <Image
          className={styles[`search-section__image`]}
          src={image.url}
          alt={image.description}
          fill
        />
      )}
      <div className={styles[`search-section__card`]}>
        {pillLabel && (
          <div className={styles[`search-section__eyebrow`]}>
            <Eyebrow as="span" styleVariant="collect">
              {pillLabel}
            </Eyebrow>
          </div>
        )}

        {(heading || body) && (
          <div className={styles[`search-section__card-text`]}>
            {heading && (
              <Heading
                as="h1"
                size="lg"
                textAlign="center"
                fgColor="accentSecondary"
              >
                {heading}
              </Heading>
            )}
            {body && <p>{body}</p>}
          </div>
        )}

        {button.label && button.url && (
          <Button
            as="link"
            variant={VARIANT.Text}
            href={button.url}
            className={styles[`search-section__card-button`]}
            trailingIcon={ICON_VARIANT.ArrowRight}
            target={button.openLinksInNewTab ? '_blank' : '_self'}
          >
            {button.label}
          </Button>
        )}

        {enableSearch && (
          <Button
            className={styles[`search-section__card-search-field-button`]}
            variant={VARIANT.Unstyled}
            onClick={() => toggleModal(modalLabel)}
          >
            <div className={styles[`search-section__card-search-field`]}>
              <Icon
                variant={ICON_VARIANT.Search}
                className={styles[`search-section__card-search-field-icon`]}
              />
              <span>{searchButtonLabel}</span>
            </div>
          </Button>
        )}
      </div>

      {enableSearch && (
        <SearchModal
          modalLabel={modalLabel}
          wasLabel={wasRateLabel ?? ''}
          title={searchButtonLabel}
          closeButtonLabel={closeButtonLabel ?? ''}
        />
      )}
    </section>
  );
};

export default SearchSection;
