import { GetServerSidePropsContext } from 'next';
import { getSession } from '@/providers/auth0';
import ContentfulProvider from '@/providers/contentful/contentful.provider';
import RecommendedRetailersProvider from '@/providers/recommended-retailers/recommended-retailers.provider';
import { COLLECTION_MECHANIC } from '@/models/collection-mechanic/collection-mechanic.types';
import Partner from '@/models/partner/partner';
import {
  IMAGE_POSITION,
  VersatileCardProperties,
} from '@/components/integrated/versatile-card/versatile-card.types';
import { VARIANT } from '@/components/integrated/partner-tile/partner-tile.types';
import { formatMicrocopy } from '@/utils/format-microcopy';
import { getMembershipId } from '@/utils/extract-user-data';
import { homeTopPicksContentfulQuery } from './home-top-picks-carousel.query';
import {
  TopPicksCarouselProperties,
  TopPicksCarouselContentfulResponse,
} from './home-top-picks-carousel.types';

const MAX_CAROUSEL_ITEMS = 12;

export const homeTopPicksCarouselHandler = async ({
  req,
  res,
}: GetServerSidePropsContext): Promise<TopPicksCarouselProperties | null> => {
  const { market, b2bToken } = req;
  const session = await getSession({ market, requestResponse: [req, res] });
  if (!session?.user) return null;

  const provider = new RecommendedRetailersProvider(market, b2bToken);
  let retailersData = await provider.getRecommendedRetailers(
    getMembershipId(session.user),
  );

  if (!retailersData)
    retailersData = await provider.getRecommendedRetailers(
      getMembershipId(session.user),
    );

  let partners: Partner[] | undefined;
  if (retailersData && retailersData.length > 0) {
    partners = await Partner.findByConditions({
      market,
      b2bToken,
      merchantIds: retailersData.map(Number),
    });

    await Promise.all(partners.map(partner => partner.isReady()));
  }

  // if no top picks found, use popular eStore partners
  if (!partners || partners?.filter(x => x.isValid).length === 0) {
    const relatedPartnersPromise = await Partner.getPopularPartners({
      collectionMechanics: [COLLECTION_MECHANIC.EStore],
      b2bToken,
      market,
    });
    await Promise.all(
      relatedPartnersPromise.data.map(partner => partner.isReady()),
    );
    partners = relatedPartnersPromise.data;
  }

  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<TopPicksCarouselContentfulResponse>({
    query: homeTopPicksContentfulQuery,
  });

  const microcopy = formatMicrocopy(
    contentfulResponse.data.microcopyCollection.items,
  );

  const topPicksForYouTitle = microcopy?.home_toppicksforyou;

  return {
    title: topPicksForYouTitle,
    favouritesLabels: {
      addToFavourites: microcopy?.favourites_button_addtofavourites ?? null,
      removeFromFavourites:
        microcopy?.favourites_button_removefromfavourites ?? null,
    },
    topPicks: partners
      .filter(x => x.isValid)
      .slice(0, MAX_CAROUSEL_ITEMS)
      .map(partner => {
        const {
          collectionMethods,
          id,
          name,
          slug,
          logoSrc,
          heroSrc,
          mechanicId,
        } = partner;

        let mechanicRate = null;
        let mechanicHasSpeedy = null;
        for (const mechanic of Object.values(collectionMethods)) {
          const { rate, isSpeedyAwarding } = mechanic;
          mechanicRate = rate;
          mechanicHasSpeedy = isSpeedyAwarding;
        }

        return {
          name,
          content: {
            partner: {
              partnerId: id,
              mechanicId,
              isSpeedyAwarding: mechanicHasSpeedy,
              ...(mechanicHasSpeedy && {
                speedyAwardText: microcopy?.speedyaward_title,
              }),
              logo: { src: logoSrc, alt: name },
              slug,
              wasRate: null,
              name,
              rate: mechanicRate,
              variant: VARIANT.Default,
            },
          },
          image: {
            src: heroSrc,
            alt: name,
            position: IMAGE_POSITION.Top,
            height: 125,
            optimised: null,
          },
        } as VersatileCardProperties;
      }),
  };
};
