import { CustomLink as Link } from '@/components/custom-link';
import classNames from 'classnames';
import Image from '@/components/data-display/image';
import { VersatileCardFavourite as HeroCardFavourite } from '@/components/integrated/versatile-card/versatile-card';
import PartnerTile from '@/components/integrated/partner-tile';
import Button from '@/components/inputs/button';
import { VARIANT } from '@/components/integrated/partner-tile/partner-tile.types';
import { ContainerTypes, HeroCardProperties } from './hero-card.types';
import styles from './hero-card.module.scss';

const Container = ({ children, link, name }: ContainerTypes) => {
  return link ? (
    <Link
      href={link}
      className={classNames(styles['hero-card__link'])}
      title={name}
      aria-label={name}
    >
      {children}
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

const HeroCard = ({
  image,
  partner,
  partnerLink,
  favourite,
  ctaLabel,
  ctaUrl,
  ctaTrailingIcon,
  ctaVariant,
}: HeroCardProperties) => {
  const PARTNER_INFO_HEIGHT = 250;
  if (!image && !(ctaLabel && ctaUrl)) {
    return null;
  }

  return (
    <div className={styles[`hero-card`]}>
      <Container link={partnerLink} name={partner?.name}>
        {image && (
          <Image
            className={styles[`hero-card__image`]}
            src={image.smallImage.url}
            alt={image.smallImage.title}
            overideHeight={
              image.smallImage.height
                ? `${image.smallImage.height - PARTNER_INFO_HEIGHT}px`
                : ''
            }
            fill
          />
        )}

        {favourite && (
          <HeroCardFavourite
            mechanicId={partner?.mechanicId}
            favourite={favourite}
            favouriteClassname={styles['hero-card__favourite']}
          />
        )}
        {partner && (
          <div className={classNames(styles[`hero-card__content`])}>
            <PartnerTile
              logo={{
                src: partner.imageSrc,
                alt: partner.imageAlt,
                logoSize: partner.logoSize,
              }}
              name={partner.name}
              rate={partner.rate}
              wasRate={partner.wasRate}
              variant={VARIANT.Inline}
              textSize={partner.textSize}
              isSpeedyAwarding={partner.isSpeedyAwarding}
              speedyAwardText={partner.speedyAwardText}
              separator
              className={styles['hero-card__content-partner-tile']}
            />
          </div>
        )}
        <div className={styles['hero-card__cta-block']}>
          <Button
            as="link"
            href={ctaUrl}
            variant={ctaVariant}
            trailingIcon={ctaTrailingIcon}
          >
            {ctaLabel}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default HeroCard;
