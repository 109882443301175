import { CustomLink as Link } from '@/components/custom-link';
import { useUser } from '@auth0/nextjs-auth0/client';
import Carousel from '@/components/data-display/carousel';
import VersatileCard from '@/components/integrated/versatile-card/versatile-card';
import SectionHeading from '@/components/integrated/section-heading/section-heading';
import { VARIANT as CAROUSEL_VARIANT } from '@/components/data-display/carousel/carousel.types';
import useHandleFavourites from '@/hooks/use-handle-favourites/use-handle-favourites';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import registerPartnerClick from '@/utils/register-partner-click/register-partner-click';
import { CLICK_LOCATION } from '@/utils/register-partner-click/register-partner-click.types';
import type { TopPicksCarouselProperties } from './home-top-picks-carousel.types';
import styles from './home-top-picks-carousel.module.scss';

const MAX_CAROUSEL_ITEMS = 8;

const HomeTopPicksCarousel = ({
  title,
  topPicks,
  sectionDescription,
  favouritesLabels,
}: TopPicksCarouselProperties) => {
  const { user } = useUser();
  const { isFavourite, toggleFavourite } = useHandleFavourites();

  const featuresToggles = useFeatureTogglesContext();
  const showFavourite =
    user && !featuresToggles.includes(FEATURE_TOGGLE_LIST.NO_FAVS);

  if (!topPicks) return null;

  return (
    <section
      aria-label={sectionDescription}
      className={styles['home-top-picks-carousel']}
    >
      <div className={styles['home-top-picks-carousel__heading']}>
        <SectionHeading title={title} />
      </div>
      <Carousel variant={CAROUSEL_VARIANT.MultiPartners}>
        {topPicks?.slice(0, MAX_CAROUSEL_ITEMS).map(retailer => (
          <Carousel.Item
            key={retailer.content.partner?.slug}
            className={styles['home-top-picks-carousel__item']}
          >
            <Link
              href={`/retailers/${retailer.content.partner?.slug}`}
              className={styles['home-top-picks-carousel__link']}
              onClick={() =>
                registerPartnerClick(
                  CLICK_LOCATION.TOP_PICKS,
                  retailer.content.partner?.slug,
                )
              }
              title={retailer.content.partner?.name}
              aria-label={retailer.content.partner?.name}
            >
              <VersatileCard
                {...retailer}
                favourite={{
                  favouritesLabels,
                  showFavourite,
                  favouriteId:
                    isFavourite(
                      retailer.content.partner?.mechanicId as number,
                    ) ?? undefined,
                  toggleFavourite,
                }}
                contentClassName={styles['home-top-picks-carousel__card']}
              />
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default HomeTopPicksCarousel;
